.SelectPoly {

    &__list {

    }
    &__item {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.3);
        
        &_p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            //margin-bottom: 10px;
            padding: 15px;
            &_name {
                width: 50%;
                color: var(--light_violet);
                font-weight: 500;

            }

            &_value {
                width: 48%;
                color: var(--violet);
                font-weight: 500;
            }
        }
    }
}