.banners__pic-item img {
  width: 100%;
}
.banners__pic-item {
  border: 2px dashed #aaa;
  width: 90%;
  border-radius: 21px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 8px;
}
.baners__pic-item--new p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 140px;
}
.banners__pageBody {
  flex-direction: column;
}